<!-- TODO: hardcoded strings to be replaced... -->
<template>
    <section class="padded-container">
        <div class="providers-list-container">
            <div v-if="loading" class="slate__wrapper">
                <div class="slate__description spinner--container">
                    <LoadingSpinner />
                </div>
            </div>

            <div v-if="showGrid">
                <h2 class="steps-header">{{ $getLocale('select_a_partner') }}</h2>
                <MVPDgrid
                    @selected="handleProviderSelected"
                    :providers="providersList.topProviders"
                    :allProviders="providersList.allProviders"
                    :isDeeplink="isDeeplink" />
                <div v-if="!isDeeplink" class="providers-list-select">
                    <CustomSelect
                        :hideIfOptionsLessThan="12"
                        v-if="providersList.allProviders.length"
                        :selectConfig="getMoreProviders"
                        @input="handleProviderSelected"
                        @focus="handleSelectBoxOpen" />
                </div>
            </div>
        </div>
        <transition name="slide-fade" mode="out-in">
            <SuccessConfirmation
                v-if="showSuccessConfirmation"
                :provider="selectedProviderInfo"
                :createAccountCallback="createAccountEvent"
                :bindAccountCallback="bindAccountEvent" />
        </transition>
        <BackToRendezvousOptions :separator="false"></BackToRendezvousOptions>
    </section>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import LoadingSpinner from 'atoms/LoadingSpinner'; // ~/src/web-package-coreui/src/js/atoms/LoadingSpinner.vue
    import CustomSelect from 'atoms/CustomSelect'; // ~/src/web-package-coreui/src/js/atoms/CustomSelect.vue
    import MVPDgrid from 'aa/vue/components/mvpd/elements/MVPDgrid'; // ~/src/web-allaccess/src/js/vue/components/mvpd/elements/MVPDgrid.vue
    import {
        checkMvpdAssociation,
        autoLoginBoundUser,
        deauthorize,
        unbindWithAuthSuite,
    } from 'services/ApiService';
    import SuccessConfirmation from 'aa/vue/components/mvpd/elements/SuccessConfirmation';
    import {
        ACTION_NAMES,
        PAGE_TYPES,
        SCREEN_NAMES,
        tracking,
        APP_LOG_TYPE,
    } from 'services/Tracking';
    import BackToRendezvousOptions from 'aa/vue/components/BackToRendezvousOptions';
    import Domain from 'helpers/Domain';
    import { AUTHZ_AUTHN_ERROR } from 'aa/vue/constants/shared';
    import { SIGNIN_URL, HOME_PATH } from 'aa/vue/constants/aaRoutes';
    import {
        USER_REG_TYPE,
        ACTION_NAMES as AA_ACTION_NAMES,
        PAGE_TYPES as AA_PAGE_TYPES,
    } from 'aa/vue/constants/aaTrackingConstants';
    import { MULTI_SUB_PLAN_PICKER_ENABLED } from 'aa/helpers/featureConstants';
    import RendezvousMixin from 'aa/vue/components/rendezvous/RendezvousMixin';
    import { FLOW_TYPE } from 'models/FlowModel';

    export default {
        name: 'mvpd',
        mixins: [RendezvousMixin],
        components: {
            LoadingSpinner,
            CustomSelect,
            MVPDgrid,
            SuccessConfirmation,
            BackToRendezvousOptions,
        },
        props: {
            setSignInVisibility: {
                type: Function,
                default: (visibility) => visibility,
            },
        },
        data: () => {
            return {
                providersList: {
                    allProviders: [],
                    topProviders: [],
                },
                selectedProviderInfo: {
                    name: 'unknown',
                },
                loading: true,
                providerAuthenticatedWithoutAccount: false,
                isDeeplink: false,
            };
        },
        computed: {
            ...mapGetters({
                isRendezvous: 'flow/isRendezvous',
                destinationUrl: 'flow/destinationUrl',
                trackingActivationInfo: 'flow/getTrackingActivationInfo',
            }),
            ...mapState('pageAttributes', {
                pageAttr: (state) => state.attributes,
            }),
            ...mapState('user', {
                userState: (state) => state,
            }),
            ...mapState('authSuite', {
                accessService: (state) => state.authSuiteServices.accessService,
                accessStatus: (state) => state.accessStatus,
                accessTokenService: (state) => state.authSuiteServices.accessTokenService,
                authorizationService: (state) => state.authSuiteServices.authorizationService,
                authSuiteLoaded: (state) => state.isLoaded,
                authSuiteRegion: (state) => state.authSuiteRegion,
                authSuiteTokens: (state) => state.tokens,
                callbackUrl: (state) => state.callbackUrl,
                linkedDeviceWithProvider: (state) => state.linkedDeviceWithProvider,
                localStorageService: (state) => state.authSuiteServices.localStorageService,
                loginEvent: (state) => state.authSuiteServices?.loginEvent,
                messagingService: (state) => state.authSuiteServices.messagingService,
                mvpdLoginEventBound: (state) => state.mvpdLoginEventBound,
                providersService: (state) => state.authSuiteServices.providersService,
                selectedProviderCode: (state) => state.selectedProviderCode,
                selectedProviderName: (state) => state.selectedProviderName,
                tokenStatus: (state) => state.tokenStatus,
                mvpdPartnerId: (state) => state.selectedProviderCode,
                mvpdPartnerName: (state) => state.selectedProviderName,
            }),
            altLogo: () => (providerName) => {
                return `logo ${providerName}}`;
            },
            plan() {
                if (this.$store.getters.featureIsActive(MULTI_SUB_PLAN_PICKER_ENABLED)) {
                    return this.$store.getters['plan/getSelectedPlan'];
                } else {
                    return this.$store.getters['plan/getCurrentPlan'];
                }
            },
            getMoreProviders: function () {
                const selectConfig = {
                    id: 'partners',
                    name: 'partners',
                    wrapperId: 'partners-select',
                    label: this.moreLabel(),
                    selected: '',
                    ref: 'partners',
                    tabIndex: 1,
                    styleClass: [],
                    options: [],
                };
                return this.providersList.allProviders.reduce((acc, value) => {
                    acc.options.push({
                        hidden: false,
                        value: {
                            clickLabel: value.primaryName,
                            mvpdPartnerId: value.code,
                            mvpdPartnerName: value.primaryName,
                        },
                        text: value.primaryName,
                    });
                    return acc;
                }, selectConfig);
            },
            showGrid: function () {
                return !this.loading && !this.providerAuthenticatedWithoutAccount;
            },
            showSuccessConfirmation: function () {
                return !this.loading && this.providerAuthenticatedWithoutAccount;
            },
        },
        created() {
            // Get Available Products
            if (this.authSuiteLoaded) {
                this.listenToLoginEvents();
                this.getProviders();
            }
        },
        watch: {
            authSuiteTokens(newValue, oldValue) {
                if (this.tokenStatus === 'valid' && this.linkedDeviceWithProvider === true) {
                    const { accessMethodsInUse } = this.accessStatus;
                    const currentAccessMethod = accessMethodsInUse.find(
                        (accessMethod) => accessMethod.providerCode === this.selectedProviderCode,
                    );
                    if (currentAccessMethod.authorizedResourceGroups.includes('paramountplus')) {
                        this.checkBinding();
                    } else {
                        this.handleAuthError();
                    }
                }
            },
            authSuiteLoaded(newValue, oldValue) {
                if (oldValue === false && newValue === true) {
                    if (this.providersList !== null) {
                        this.getProviders();
                        this.listenToLoginEvents();
                    }
                }
            },
            linkedDeviceWithProvider(newValue, oldValue) {
                if (newValue === true && oldValue === false) {
                    this.checkAccessStatus();
                }
            },
            showSuccessConfirmation(newValue, oldValue) {
                if (oldValue === false && newValue === true) {
                    this.setSignInVisibility(false);
                    tracking.trackState({
                        pageType: AA_PAGE_TYPES.RENDEZVOUS_SIGNIN_COMPLETE,
                        pageName: SCREEN_NAMES.CONFIRMATION_SCREEN,
                        mvpdPartnerId: this.selectedProviderCode,
                        mvpdPartnerName: this.selectedProviderName,
                        pageUrl: window.location.href,
                    });
                }
                if (oldValue === true && newValue === false) {
                    this.setSignInVisibility(true);
                }
            },
            showGrid(newValue, oldValue) {
                if (
                    oldValue === false &&
                    newValue === true &&
                    utag_data.pageType !== PAGE_TYPES.TV_PROVIDER_SELECTION_SCREEN
                ) {
                    tracking.trackState({
                        pageType: PAGE_TYPES.TV_PROVIDER_SELECTION_SCREEN,
                        pageName: Domain.isDomestic()
                            ? PAGE_TYPES.TV_PROVIDER_SELECTION_SCREEN
                            : this.$route.path,
                        ftag: this.$route.query.ftag,
                        pageUrl: window.location.href,
                        authPartnerId: this.selectedProviderName,
                        ...this.trackingActivationInfo,
                    });
                }
            },
        },
        methods: {
            ...mapMutations('authSuite', {
                updateSelectedProviderCode: 'updateSelectedProviderCode',
                updateSelectedProviderName: 'updateSelectedProviderName',
            }),
            providerLoginCallback({
                regId = null,
                redirectPath = HOME_PATH,
                success = false,
                message = '',
            } = {}) {
                this.loading = false;
                if (success === true) {
                    this.trackAuthSuccess(regId);
                    window.location.href = redirectPath;
                } else if (success === false) {
                    if (message === 'method not allowed for RedemptionCode provider') {
                        const { accessMethodsInUse } = this.accessStatus;
                        this.loginEvent.emitFail({
                            errorType: AUTHZ_AUTHN_ERROR,
                            overrides: {
                                brandName: 'Paramount+',
                                partner: accessMethodsInUse[0].cobranding.name,
                            },
                            heading: 'mvpd_connection_already_exists_headline',
                            subHeading: 'mvpd_connection_already_exists_subheadline',
                            primaryButton: {
                                label: 'sign_in',
                                link: SIGNIN_URL,
                            },
                        });
                    } else {
                        this.emitAuthNError({
                            code: this.selectedProviderCode,
                            name: this.selectedProviderName,
                        });
                    }
                }
            },
            async handleAuthError() {
                this.loading = true;
                await this.authorizationService.disconnectDevice();
                deauthorize();
                this.emitAuthZError({
                    code: this.selectedProviderCode,
                    name: this.selectedProviderName,
                });
            },
            bindAccountEvent() {
                this.$router.push({
                    name: 'SIGNIN',
                    params: { fromConfirmation: true },
                });
            },
            async checkBinding() {
                const httpData = {
                    _clientRegion: this.authSuiteRegion,
                    authSuiteToken: this.authSuiteTokens.applicationAccessToken,
                };
                // If user is registered, then link with the API.
                if (this.userState.isRegistered || this.userState.isExSubscriber) {
                    this.registerWithApi({
                        errorCallBack: this.emitAlreadyBoundPromoCodeError,
                        trackBindSuccess: this.trackRegExSubAccountBindingSuccess,
                    });
                } else {
                    const isBound = await checkMvpdAssociation(httpData);
                    this.loading = true;
                    //If user is bound with an account, then log in. Otherwise, direct to sign-up.
                    if (isBound) {
                        this.trackAccountBindingSuccess();
                        const redirectPath = this.isRendezvous ? this.destinationUrl : HOME_PATH;
                        autoLoginBoundUser(httpData, redirectPath, this.providerLoginCallback);
                    } else {
                        this.getAuthenticatedProvider(this.selectedProviderCode);
                    }
                }
            },
            checkStatus() {
                this.checkAccessStatus();
            },
            ...mapActions('authSuite', {
                bindLoginEventHandler: 'bindLoginEventHandler',
                checkAccessStatus: 'checkAccessStatus',
                emitAlreadyBoundPromoCodeError: 'emitAlreadyBoundPromoCodeError',
                emitAuthNError: 'emitAuthNError',
                emitAuthZError: 'emitAuthZError',
                linkDeviceWithProvider: 'linkDeviceWithProvider',
                registerWithApi: 'registerWithApi',
                trackAuthNFail: 'trackAuthFail',
            }),
            createAccountEvent() {
                this.$router.push({
                    name: 'SIGNUP',
                    params: { fromConfirmation: true },
                });
            },
            listenToLoginEvents() {
                if (!this.mvpdLoginEventBound) {
                    this.bindLoginEventHandler(this.linkDeviceWithProvider);
                }
            },
            async getProviders() {
                let authSuiteProviders;
                try {
                    authSuiteProviders = await this.providersService.fetchProviders('black');
                    this.providersList = authSuiteProviders;
                    const authorizationCode = this.$route.query.authorizationCode;
                    const mvpdCode =
                        this.$route.query.mvpdCode ||
                        this.$store.getters['authSuite/selectedProviderCode'];
                    const providerData = this.findProviderByCode(mvpdCode);

                    if (this.$route.params.TVProviderID) {
                        this.initProviderDeeplink();
                    } else if (authorizationCode && mvpdCode && providerData) {
                        this.handleMvpdCallback(authorizationCode, mvpdCode, providerData);
                    } else {
                        this.loading = false;
                    }
                } catch (error) {
                    this.providersList = null;
                    this.loginEvent.emitFail(error);
                }
            },
            async getAuthenticatedProvider(mvpdCode) {
                this.getFullProviderInformation(mvpdCode)
                    .then((res) => {
                        this.selectedProviderInfo = {
                            ...res.cobranding,
                        };
                        this.updateSelectedProviderName(this.selectedProviderInfo.name);
                        if (Domain.isInternational()) {
                            this.providerAuthenticatedWithoutAccount = true;
                            this.loading = false;
                        } else if (this.isRendezvousMvpdSignIn()) {
                            this.providerAuthenticatedWithoutAccount = true;
                            this.loading = false;
                        } else {
                            this.createAccountEvent();
                        }
                    })
                    .catch((error) => {
                        this.loginEvent.emitFail(error);
                    });
            },
            getFullProviderInformation(mvpdCode) {
                return this.providersService.getMvpdProvider({
                    mvpdCode: mvpdCode,
                    logoSchema: 'black',
                });
            },
            handleSelectBoxOpen(e) {
                tracking.trackAction(ACTION_NAMES.TV_PROVIDER_MORE, {
                    clickLabel: this.moreLabel(),
                });
            },
            handleProviderSelected(selectedProvider) {
                if (selectedProvider && selectedProvider.value) {
                    this.updateSelectedProviderName(selectedProvider.value.mvpdPartnerName);
                    this.selectedProviderInfo.name = selectedProvider.value.mvpdPartnerName;
                    tracking.trackAction(ACTION_NAMES.TV_PROVIDER_SELECTION, {
                        clickLabel: selectedProvider.value.clickLabel || '',
                        mvpdPartnerId: selectedProvider.value.mvpdPartnerId || '',
                        mvpdPartnerName: selectedProvider.value.mvpdPartnerName || '',
                        ctaText: selectedProvider.value.mvpdPartnerName || '',
                        authPartnerId: selectedProvider.value.mvpdPartnerId || '',
                        ...this.trackingActivationInfo,
                    });
                    const { mvpdPartnerId: mvpdCode } = selectedProvider.value;
                    let authLocation = window;
                    const callbackUrl = window.location.href.replace(
                        `${this.$route.params.TVProviderID}/`,
                        '',
                    );
                    this.updateSelectedProviderCode(mvpdCode);
                    return this.authorizationService
                        .getAuthenticationUrl({
                            callbackUrl: callbackUrl,
                            mvpdCode,
                        })
                        .then((resp) => {
                            authLocation.location.href = resp.authenticationUrl;
                        })
                        .catch((error) => {
                            this.emitAuthNError({
                                code: this.selectedProviderCode,
                                name: this.selectedProviderName,
                            });
                        });
                }
            },
            trackAuthSuccess(regId) {
                tracking.trackAction(ACTION_NAMES.TV_PROVIDER_SUCCESS, {
                    clickLabel: '',
                    mvpdPartnerId: this.selectedProviderCode,
                    mvpdPartnerName: this.selectedProviderName,
                    mvpdEventSuccess: 1,
                    userRegType: 'MVPD_AuthSuite',
                    userRegId: regId || '',
                });
            },
            trackAccountBindingSuccess() {
                let planCode = this.plan?.recurlyCode ?? 'mvpd';
                let accountBindingSuccessTrackingParams = {
                    mvpdPartnerId: this.selectedProviderCode,
                    mvpdPartnerName: this.selectedProviderName,
                    purchaseProduct: planCode,
                    purchaseEventOrderComplete: '1',
                    purchaseQuantity: '1',
                    authPartnerId: this.selectedProviderName,
                };

                tracking.trackAction(
                    AA_ACTION_NAMES.MVPD_ACCOUNT_BINDING_SUCCESS,
                    accountBindingSuccessTrackingParams,
                );
            },
            trackRegExSubAccountBindingSuccess(user) {
                let trackActionName = AA_ACTION_NAMES.MVPD_ACCOUNT_BINDING_SUCCESS;
                let purchaseTrackingParams = {
                    userRegType: USER_REG_TYPE.MVPD,
                    mvpdPartnerId: this.selectedProviderCode,
                    mvpdPartnerName: this.selectedProviderName,
                    authPartnerId: this.selectedProviderName,
                    pageType: PAGE_TYPES.SIGNIN,
                    purchaseEventOrderComplete: '1',
                    purchaseQuantity: '1',
                    purchaseProduct: user.svod.user_package.product_code,
                    pickPlanType: user.svod.user_package.product_name,
                    pickPlanSku: user.svod.user_package.product_code,
                    purchaseProductName: user.svod.user_package.product_name,
                    purchasePrice: 'na',
                    vendorCode: user.svod.user_package.vendor_code,
                };

                tracking.trackAction(trackActionName, purchaseTrackingParams);
            },
            moreLabel() {
                return this.$getLocale('more_partners');
            },
            findProviderByCode(mvpdCode) {
                if (typeof mvpdCode !== 'string') {
                    return null;
                }

                return this.providersList.allProviders.find((provider) => {
                    return provider.code.toLowerCase() === mvpdCode.toLowerCase();
                });
            },
            attachProviderImage(provider) {
                return this.getFullProviderInformation(provider.code)
                    .then((res) => {
                        if ('cobranding' in res) {
                            provider.pickerLogoUrl = res.cobranding.logoUrl;
                        }
                    })
                    .catch(() => {
                        console.error('Error fetching full provider information');
                    });
            },
            initProviderDeeplink() {
                const mvpdCode = this.$route.params.TVProviderID;
                const foundProvider = this.findProviderByCode(mvpdCode);
                if (foundProvider) {
                    const handleProviderSelected = () => {
                        this.handleProviderSelected({
                            hidden: false,
                            value: {
                                clickLabel: foundProvider.primaryName,
                                mvpdPartnerId: foundProvider.code,
                                mvpdPartnerName: foundProvider.primaryName,
                            },
                            text: foundProvider.primaryName,
                        });
                    };
                    // Get logo if necessary
                    if (!('pickerLogoUrl' in foundProvider)) {
                        this.attachProviderImage(foundProvider)
                            .then(() => {
                                // isDeeplink needs to be set here to trigger re-rendering
                                // the mvpdGrid when there is an image
                                this.isDeeplink = true;
                                this.loading = false;
                                handleProviderSelected();
                            })
                            .catch(() => {
                                console.error('Error fetching full provider information');
                            });
                    } else {
                        this.isDeeplink = true;
                        this.loading = false;
                        handleProviderSelected();
                    }
                } else {
                    this.loading = false;
                }
            },
            handleMvpdCallback(authorizationCode, mvpdCode, providerData) {
                this.isDeeplink = true;
                this.updateSelectedProviderCode(mvpdCode);
                this.updateSelectedProviderName(providerData.primaryName);
                this.selectedProviderInfo.name = providerData.primaryName;
                // Get logo if necessary
                if (!('pickerLogoUrl' in providerData)) {
                    this.attachProviderImage(providerData).then(() => {
                        this.loading = false;
                        this.linkDeviceWithProvider(authorizationCode);
                    });
                } else {
                    this.loading = false;
                    this.linkDeviceWithProvider(authorizationCode);
                }
            },
        },
    };
</script>
