<template>
    <div :class="className">
        <span v-html="disclaimer" />
    </div>
</template>

<script>
    export default {
        name: 'PickAPlanDisclaimer',
        props: {
            disclaimer: {
                type: String,
                required: true,
            },
            className: {
                type: [String, Array],
            },
        },
        data: function () {
            return {};
        },
    };
</script>
